export const dataMaskaTokens = JSON.stringify({
  '#': { pattern: '[0-9]' },
  'N': { pattern: '[0-9a-zA-Z]' },
  'A': { pattern: '[a-zA-Z]' },
})

export function isStrongPassword(v: string) {
  return v.length >= 8 && /^(?=.*[A-Z])(?=.*[^0-9a-zA-Z ])(?=.*[0-9])/.test(v)
}

export function isValidName(name: string) {
  const validNamePattern = /^[a-zA-ZÀ-ÿ\\u00f1\\u00d1]+(?:[-'\s][a-zA-ZÀ-ÿ\\u00f1\\u00d1]+)*$/
  if (validNamePattern.test(name)) {
    return true
  }
  return false
}

export function satisfiesRegex(str: string, regex: RegExp) {
  const valid = regex.test(str)
  return valid
}

export const getDataOnboarding = ({ userMcId = '', key = '' }) => {
  const authStore = useAuthStore()
  const userMetadata = authStore.userMetadata
  if (!userMcId || !key) return ''
  try {
    const dataOnboarding = window.localStorage.getItem(`${userMcId}-data-onboarding`)
    if (userMetadata[key]) {
      return userMetadata[key] ?? ''
    } else if (dataOnboarding) {
      return JSON.parse(dataOnboarding)[key]
    }
    return ''
  } catch (e) {
    return ''
  }
}
